import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

const routes = [
  {
    path: '/',
    redirect: '/configurator'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/configurator',
    name: 'configurator',
    component: () => import('../layouts/ConfiguratorLayout.vue'),
    children: [
      {
        path: '',
        redirect: '/configurator/language-model'
      },
      {
        path: 'language-model',
        meta: { auth: true },
        component: () => import('../views/LanguageModel.vue')
      },
      {
        path: 'app-type',
        meta: { auth: true },
        component: () => import('../views/AppType.vue')
      },
      {
        path: 'data-source',
        meta: { auth: true },
        component: () => import('../views/DataSource.vue')
      },
      {
        path: 'url-name',
        meta: { auth: true },
        component: () => import('../views/UrlName.vue')
      },
      {
        path: 'generate-app',
        meta: { auth: true },
        component: () => import('../views/Generating.vue')
      },
      {
        path: 'success',
        meta: { auth: true },
        component: () => import('../views/Success.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === 'login') {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', next)
    : next();
});

export default router
